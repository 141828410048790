<template>
  <div
    v-if="school"
    class="page has-background-blue with-title"
  >
    <h1 class="page-title has-text-weight-bold has-text-centered is-size-5">
      {{ school.title }}
    </h1>
    <div class="container">
      <div class="columns is-multiline is-mobile">
        <div class="column is-12-mobile is-6-tablet">
          <b-carousel :autoplay="false">
            <b-carousel-item
              v-for="(item, i) in carouselItems"
              :key="i"
            >
              <b-image
                class="image carousel-image"
                :src="item.image"
                :alt="item.title"
              />
            </b-carousel-item>
          </b-carousel>
          <p
            class="px-4 pt-4"
            v-html="school.description"
          />
        </div>
        <div class="column is-12-mobile is-6-tablet">
          <div class="is-flex is-justify-content-center px-4">
            <a
              v-for="link in links"
              :key="link.id"
              class="link-item"
              :href="link.url"
              target="_blank"
            >
              <b-icon
                :style="{color: link.icon.color}"
                :pack="link.icon.pack"
                :icon="link.icon.icon"
                size="is-large"
              />
            </a>
          </div>
          <div class="p-4">
            <div
              v-if="!isAuth"
              class="mb-2 has-text-centered"
            >
              <b-button
                type="is-secondary"
                @click="openSchoolRegistration"
              >
                Regístrate en {{ school.title }}
              </b-button>
            </div>
            <a
              v-if="school.email"
              class="link-item is-block mb-2"
              :href="`mailto:${school.email}`"
              target="_blank"
            >
              <b-icon
                size="is-small"
                icon="envelope"
              />
              {{ school.email }}</a>
            <a
              v-if="school.phone"
              class="link-item is-block"
              :href="`tel:${school.phone}`"
              target="_blank"
            >
              <b-icon
                size="is-small"
                icon="phone"
              />
              {{ school.phone }}</a>
            <template v-if="hasAddress">
              <h2 class="is-black py-4">
                Localización
              </h2>
              <p v-html="address" />
            </template>
          </div>
          <template v-if="hasMapLocation">
            <l-map
              style="height: 300px; z-index: 30;"
              :zoom="map.zoom"
              :center="map.center"
            >
              <l-tile-layer
                :url="map.url"
                :attribution="map.attribution"
              />
              <l-marker :lat-lng="map.markerLatLng">
                <l-tooltip :options="{ permanent: true}">
                  {{ school.title }}
                </l-tooltip>
              </l-marker>
            </l-map>
          </template>
        </div>
        <div class="column is-12-mobile is-12-tablet">
          <school-contact-form :school="school"
                               :autofocus="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";
import {LMap, LTileLayer, LTooltip, LMarker} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {Icon} from 'leaflet';
import SchoolContactForm from "@/components/schools/SchoolContactForm";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  title: 'Escuela',
  name: 'SchoolDetail',
  components: {
    SchoolContactForm,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  data: function () {
    return {
      map: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 12,
        center: [51.505, -0.159],
        markerLatLng: [51.504, -0.159],
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      },
      icons: {
        default: {pack: 'fas', icon: 'link', color: '#06080E'},
        wa: {pack: 'fab', icon: 'whatsapp', color: '#06080E'},
        ins: {pack: 'fab', icon: 'instagram', color: '#06080E'},
        fb: {pack: 'fab', icon: 'facebook', color: '#06080E'},
        web: {pack: 'fas', icon: 'link', color: '#06080E'}
      },
      section_title: 'Escuela',
      school: null
    }
  },
  computed: {
    emptyResult() {
      return this.school === null
    },
    userToken() {
      return this.$store.state.token
    },
    carouselItems() {
      const mainImage = {
        image: this.getImagePath(this.school.image),
        title: this.school.title
      }

      const photos = [mainImage]
      if (this.school?.photos) {
        console.info("Tiene fotos")
        this.school.photos.forEach((item) => {
          photos.push({image: this.getImagePath(item.image), title: item.title})
        })
      }

      return photos
    },
    hasMapLocation(){
      return this.school.geo.lat && this.school.geo.lng
    },
    hasAddress(){
      return this.school.address.street && this.school.address.city
    },
    address() {
      let addressStr = this.school.address.street
      if (this.school.address.street_ext) {
        addressStr += `<br/>${this.school.address.street_ext}`
      }
      addressStr += `<br/>${this.school.address.city}, ${this.school.address.state} (${this.school.address.postal_code})`
      addressStr += `<br/>${this.school.address.country}`
      return addressStr
    },
    links() {
      if (this.school?.phone_wa) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.school.urls['wa'] = `https://wa.me/${this.school.phone_wa}`
      }

      const keys = Object.keys(this.school.urls)
      return keys.map((id) => {
        const url = this.school.urls[id]
        if (!url) {
          return null
        }

        const icon = this.icons.hasOwnProperty(id) ? this.icons[id] : this.icons.default

        return {
          id,
          url,
          icon
        }
      })
        .filter(item => item !== null)
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    }
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    async openSchoolRegistration(){
      try{
        await this.$router.push({name: 'register', query: { school_id: this.$route.params.id }})
      }catch (e) {
        this.$msg.error('No ha sido posible abrir la página de registro de la escuela')
      }
    },
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const schoolId = this.$route.params.id
        const school = await this.$api.school.getItem(schoolId)

        if (school?.geo?.lat && school?.geo?.lng) {
          this.map.center = [school.geo.lat, school.geo.lng]
          this.map.markerLatLng = [school.geo.lat, school.geo.lng]
        }

        this.school = school

        this.setPageTitle(this.school.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    }
  }
}
</script>

<style scoped>
a.link-item {
  color: #06080E;
}
</style>
